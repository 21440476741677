export default function Mute({
  colorClass = "text-white",
  widthClass,
  heightClass,
}: {
  colorClass?: string;
  widthClass: string;
  heightClass: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300.24 211.55"
      className={`
        ${widthClass} ${heightClass}
        fill-current select-none
        ${colorClass}
      `}
    >
      <path d="m0,75.48c2.03-4.84,4.87-8.89,10.39-10.09,1.51-.33,3.09-.43,4.63-.43,11.72-.03,23.45-.07,35.17.02,2.19.02,3.84-.6,5.53-2,23.65-19.68,47.34-39.31,71.05-58.92,6.02-4.98,12.2-5.37,17.95-1.23,3.08,2.22,4.84,5.3,5.34,9.05.18,1.35.17,2.73.17,4.1,0,59.89-.02,119.78.05,179.67,0,6.68-2.11,11.89-8.42,14.7-5.39,2.39-10.29,1.07-14.7-2.58-12.18-10.09-24.37-20.19-36.55-30.28-11.81-9.78-23.58-19.61-35.46-29.3-1.16-.94-2.94-1.56-4.44-1.58-11.14-.13-22.28-.36-33.41.03-8.44.3-14.37-2.55-17.28-10.73v-60.41Z" />
      <g>
        <path d="m256.24,132.91v2.58c-.06-.28-.12-.55-.19-.83-.04.11-.05.22-.05.32,0,.11.02.22.03.33,0,.2.05.41.01.6-.79,3.88-3.11,6.38-6.87,7.56-.98.31-2,.22-3,.22-2.29,0-4.22-.9-5.87-2.41-1.14-1.04-2.17-2.19-3.25-3.28-2.11-2.14-4.23-4.28-6.35-6.42-2.2-2.21-4.4-4.41-6.61-6.62-1.2-1.2-2.41-2.4-3.61-3.61-.68-.68-1.35-1.36-2.04-2.06-.07.06-.14.12-.21.19-1.09,1.1-2.18,2.2-3.27,3.3-4.17,4.21-8.34,8.43-12.51,12.64-1.8,1.82-3.61,3.63-5.41,5.45-.75.76-1.59,1.41-2.55,1.87-1.2.59-2.45.96-3.83.95-1.46,0-2.87-.13-4.25-.7-2.88-1.21-5.73-4.63-5.55-8.14.04-.75-.07-1.51.03-2.25.27-1.83,1.09-3.42,2.34-4.77,1.01-1.08,2.06-2.13,3.1-3.18,1.59-1.6,3.2-3.19,4.79-4.8,2.22-2.24,4.44-4.5,6.66-6.74,2.36-2.39,4.72-4.78,7.09-7.16.14-.14.17-.23.01-.39-1.81-1.83-3.6-3.67-5.4-5.5-1.99-2.01-3.98-4.02-5.98-6.03-3.09-3.12-6.18-6.23-9.27-9.35-.4-.4-.82-.79-1.19-1.23-1.37-1.64-2.27-3.47-2.18-5.68.03-.66,0-1.32,0-1.98,0-.14.01-.28.04-.42.49-2.36,1.69-4.28,3.59-5.74,2.05-1.58,4.4-2.06,6.93-1.79,2.13.23,3.95,1.16,5.46,2.67,1.71,1.71,3.42,3.42,5.12,5.14,2.25,2.26,4.48,4.54,6.73,6.81,3.04,3.07,6.08,6.14,9.12,9.2.2.2.42.39.62.58,1.3-1.33,2.57-2.62,3.84-3.91,2.15-2.18,4.29-4.36,6.45-6.53,3.77-3.79,7.54-7.59,11.34-11.36,2.37-2.35,5.27-3.12,8.49-2.49,3.28.65,5.57,2.62,6.88,5.7.35.83.64,1.68.51,2.61-.03.2,0,.41,0,.61.02,0,.05,0,.07,0,.06-.35.11-.7.17-1.06v2.52c-.06-.28-.13-.55-.19-.83-.04.17-.07.33-.04.48.15.77-.08,1.48-.3,2.2-.52,1.71-1.6,3.06-2.84,4.31-2.13,2.14-4.25,4.29-6.37,6.44-2.04,2.06-4.09,4.12-6.13,6.17-2.69,2.71-5.38,5.42-8.07,8.13-.16.16-.33.32-.5.49.08.09.14.15.2.21,1.98,2,3.97,4.01,5.95,6.01,2.2,2.23,4.39,4.46,6.6,6.68,2.22,2.24,4.46,4.47,6.69,6.71.77.77,1.53,1.55,2.29,2.33.88.91,1.58,1.93,2.01,3.13.29.8.58,1.6.47,2.47-.02.18,0,.37,0,.56.03,0,.06,0,.08,0,.05-.32.1-.64.16-.96Z" />
        <path d="m180.64,75.91c.03.69.07,1.37.09,2.06,0,.19-.06.39-.09.58,0-.88,0-1.76,0-2.64Z" />
        <path d="m180.64,132.97c.03.67.07,1.33.09,2,0,.21-.06.42-.09.64,0-.88,0-1.76,0-2.64Z" />
      </g>
    </svg>
  );
}
