export default function Check({
  colorClass = "text-gray-900",
  widthClass = "w-[14px]",
  heightClass = "h-[16px]",
  className = "",
}: {
  colorClass?: string;
  widthClass?: string;
  heightClass?: string;
  className?: string;
}) {
  return (
    <svg
      viewBox="0 0 448 512"
      className={`fill-current select-none transform transition-transform ${widthClass} ${heightClass} ${colorClass} ${className}`}
    >
      <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
    </svg>
  );
}
