export default function Logo({
  bgColor = "#171717",
  colorClass,
  widthClass,
  heightClass,
}: {
  bgColor?: string;
  colorClass: string;
  widthClass: string;
  heightClass: string;
}) {
  return (
    <svg
      viewBox="0 0 169 57"
      fill="none"
      className={`
      ${heightClass} ${widthClass}
      ${colorClass}
      fill-current
      stroke-none
    `}
    >
      <path
        d="M0 8.69869L166.461 0L169 48.3013L2.53869 57L0 8.69869Z"
        fill={bgColor}
      />
      <path d="M25.4341 24.1366C24.8829 23.5078 24.1747 23.0282 23.3095 22.6977C22.4666 22.3425 21.6576 22.1852 20.8825 22.2257C20.4832 22.2466 20.0741 22.3032 19.655 22.3955C19.2594 22.4866 18.9028 22.6462 18.585 22.8741C18.2661 23.0787 17.9978 23.351 17.7801 23.6911C17.5847 24.0066 17.5 24.4103 17.5258 24.9021C17.548 25.3236 17.6486 25.6706 17.8278 25.943C18.0305 26.2142 18.3018 26.4466 18.6417 26.6402C19.005 26.8326 19.4265 27.0101 19.9061 27.173C20.3844 27.3123 20.9221 27.4604 21.5191 27.617C22.3794 27.8539 23.2768 28.124 24.2112 28.4274C25.1444 28.7074 26.0016 29.1088 26.7827 29.6316C27.5638 30.1544 28.2231 30.8244 28.7607 31.6417C29.297 32.4356 29.5977 33.4531 29.663 34.6943C29.7381 36.1229 29.5332 37.3782 29.0484 38.4603C28.5859 39.5177 27.9262 40.4093 27.0694 41.1351C26.2126 41.8609 25.2174 42.4178 24.0838 42.8058C22.9502 43.1938 21.7727 43.4197 20.5514 43.4835C18.7664 43.5768 17.0242 43.3625 15.3246 42.8408C13.6238 42.2956 12.1913 41.4781 11.0272 40.3883L14.7624 36.1775C15.4125 36.895 16.2442 37.4855 17.2578 37.9492C18.2935 38.3882 19.3046 38.582 20.2911 38.5304C20.7373 38.5071 21.1694 38.4376 21.5872 38.3218C22.005 38.206 22.3611 38.0348 22.6553 37.8081C22.9731 37.5801 23.2166 37.2856 23.3861 36.9245C23.5555 36.5634 23.6273 36.1369 23.6014 35.6451C23.5768 35.1768 23.4385 34.7848 23.1864 34.4692C22.9343 34.1536 22.5783 33.8786 22.1185 33.6444C21.681 33.3854 21.1279 33.1678 20.4592 32.9914C19.8127 32.7903 19.0717 32.5825 18.2362 32.3678C17.4241 32.1519 16.6213 31.8886 15.8278 31.5778C15.0577 31.2658 14.3532 30.8564 13.7143 30.3497C13.0976 29.8183 12.581 29.1761 12.1646 28.4229C11.7704 27.6451 11.5438 26.6941 11.4847 25.57C11.4121 24.1883 11.6318 22.9909 12.1438 21.9779C12.6558 20.9648 13.3531 20.1182 14.2359 19.438C15.1186 18.7579 16.128 18.2472 17.2641 17.906C18.3989 17.5414 19.5535 17.3285 20.7279 17.2671C22.1371 17.1935 23.5833 17.3762 25.0665 17.8153C26.5733 18.2532 27.905 18.9468 29.0618 19.8961L25.4341 24.1366Z" />
      <path d="M44.7135 30.8081C44.6655 29.8947 44.3307 29.1255 43.709 28.5005C43.1109 27.8742 42.2364 27.5912 41.0856 27.6513C40.5219 27.6808 40.0101 27.8014 39.5502 28.0133C39.0891 28.2018 38.6904 28.4575 38.3541 28.7803C38.0178 29.1032 37.755 29.4809 37.5658 29.9135C37.3754 30.3227 37.2801 30.7503 37.28 31.1965L44.7135 30.8081ZM50.1495 32.7432C50.1618 32.9773 50.1741 33.2115 50.1864 33.4457C50.1988 33.6799 50.1987 33.903 50.1863 34.115L37.4683 34.7796C37.5411 35.2689 37.7054 35.7065 37.9612 36.0924C38.2405 36.477 38.576 36.8117 38.9678 37.0965C39.3819 37.3567 39.8281 37.5564 40.3064 37.6958C40.8082 37.834 41.3175 37.8896 41.8342 37.8626C42.7502 37.8147 43.5166 37.6103 44.1335 37.2493C44.7492 36.8648 45.2425 36.3929 45.6135 35.8334L49.7626 38.1528C49.0034 39.3901 47.9599 40.3722 46.6321 41.0991C45.3266 41.8014 43.7814 42.1991 41.9964 42.2924C40.6811 42.3611 39.4259 42.2271 38.2306 41.8904C37.0341 41.5302 35.9694 40.987 35.0362 40.2608C34.1254 39.51 33.3815 38.5743 32.8044 37.4538C32.2509 36.3321 31.9354 35.0335 31.8578 33.5581C31.7827 32.1296 31.9616 30.8286 32.3945 29.6553C32.8262 28.4586 33.4439 27.4401 34.2476 26.5996C35.05 25.7358 36.0149 25.0513 37.1424 24.5462C38.2699 24.0411 39.503 23.7536 40.8417 23.6837C42.1335 23.6161 43.3312 23.7766 44.435 24.1651C45.5375 24.5302 46.4979 25.1023 47.316 25.8815C48.1342 26.6607 48.7854 27.6247 49.2697 28.7735C49.7775 29.9211 50.0707 31.2443 50.1495 32.7432Z" />
      <path d="M59.2313 27.6189L59.5821 34.2933C59.6252 35.1129 59.8104 35.7256 60.1379 36.1311C60.4877 36.5121 61.0735 36.6811 61.8956 36.6381C62.1774 36.6234 62.4698 36.5846 62.7726 36.5218C63.099 36.4578 63.3654 36.3734 63.5718 36.2687L63.8639 40.4804C63.472 40.6418 62.9732 40.7853 62.3674 40.9109C61.7629 41.0599 61.1554 41.1503 60.5447 41.1822C59.3704 41.2436 58.3766 41.1546 57.5632 40.9153C56.7487 40.6526 56.0807 40.2648 55.5591 39.752C55.0599 39.2145 54.6848 38.5765 54.4341 37.8382C54.1821 37.0764 54.0315 36.2272 53.9822 35.2904L53.5945 27.9135L50.7761 28.0607L50.549 23.74L53.3322 23.5945L53.0903 18.9927L58.7623 18.6963L59.0042 23.2981L63.1261 23.0827L63.3532 27.4035L59.2313 27.6189Z" />
      <path d="M88.6385 39.2915L88.1363 29.7366C88.1105 29.2448 88.0506 28.7782 87.9568 28.337C87.8617 27.8723 87.7116 27.4809 87.5065 27.1629C87.3013 26.8448 87.0294 26.6007 86.6908 26.4305C86.3744 26.2357 85.9578 26.1518 85.4411 26.1788C84.9479 26.2046 84.5189 26.3327 84.1542 26.5631C83.813 26.7923 83.5224 27.0893 83.2826 27.454C83.0661 27.8176 82.9115 28.2366 82.8187 28.7111C82.7247 29.1622 82.6906 29.6337 82.7164 30.1255L83.2131 39.575L77.4354 39.8769L76.911 29.9005C76.8581 28.8935 76.6166 28.1077 76.1866 27.5431C75.7565 26.9784 75.107 26.7189 74.238 26.7643C73.322 26.8121 72.6248 27.2126 72.1462 27.9655C71.6912 28.7173 71.4913 29.6201 71.5467 30.6739L72.0452 40.1586L66.2323 40.4623L65.3128 22.9685L70.9144 22.6758L71.0418 25.0996L71.1122 25.0959C71.3039 24.7102 71.5432 24.3337 71.83 23.9664C72.1404 23.598 72.5119 23.2733 72.9446 22.9924C73.3761 22.688 73.8576 22.4398 74.3892 22.2476C74.9443 22.0542 75.5506 21.9403 76.2082 21.906C77.4765 21.8397 78.5587 22.0415 79.4548 22.5113C80.3743 22.9798 81.106 23.6813 81.6497 24.6157C82.1888 23.6717 82.9022 22.9064 83.7899 22.3199C84.6776 21.7334 85.779 21.4058 87.0943 21.3371C88.2921 21.2745 89.302 21.4448 90.1239 21.8481C90.9681 22.2266 91.6552 22.7543 92.1854 23.4311C92.7143 24.0845 93.1072 24.8389 93.3641 25.6943C93.6433 26.5252 93.8044 27.3504 93.8475 28.17L94.4162 38.9895L88.6385 39.2915Z" />
      <path d="M103.308 15.3822C103.331 15.8272 103.26 16.2536 103.093 16.6616C102.948 17.0449 102.73 17.385 102.44 17.682C102.173 17.9777 101.832 18.2186 101.418 18.4047C101.027 18.5895 100.609 18.6935 100.163 18.7168C99.2232 18.7659 98.4204 18.5026 97.7542 17.9268C97.0869 17.3277 96.7304 16.5948 96.6849 15.7283C96.6627 15.3068 96.724 14.9044 96.8687 14.5211C97.0122 14.1144 97.2292 13.7625 97.5198 13.4656C97.8103 13.1686 98.1509 12.9277 98.5416 12.7429C98.931 12.5347 99.3606 12.4183 99.8303 12.3938C100.277 12.3704 100.704 12.4303 101.112 12.5734C101.543 12.7152 101.907 12.9193 102.203 13.1856C102.524 13.4507 102.776 13.778 102.962 14.1675C103.169 14.5324 103.285 14.9373 103.308 15.3822ZM98.3207 38.7855L97.4012 21.2916L103.179 20.9897L104.098 38.4836L98.3207 38.7855Z" />
      <path d="M120.124 37.6461L116.072 32.4685L112.48 38.0456L105.786 38.3954L112.315 28.8606L105.536 20.8666L112.265 20.5149L115.787 25.0158L118.853 20.1707L125.37 19.8301L119.502 28.485L126.924 37.2908L120.124 37.6461Z" />
      <path d="M139.263 25.8672C139.215 24.9539 138.88 24.1847 138.259 23.5597C137.661 22.9334 136.786 22.6503 135.635 22.7105C135.072 22.7399 134.56 22.8606 134.1 23.0725C133.639 23.261 133.24 23.5166 132.904 23.8395C132.567 24.1623 132.305 24.5401 132.115 24.9727C131.925 25.3818 131.83 25.8095 131.83 26.2557L139.263 25.8672ZM144.699 27.8023C144.711 28.0365 144.724 28.2707 144.736 28.5049C144.748 28.7391 144.748 28.9622 144.736 29.1742L132.018 29.8388C132.091 30.3281 132.255 30.7657 132.511 31.1515C132.79 31.5362 133.126 31.8709 133.517 32.1557C133.931 32.4158 134.378 32.6156 134.856 32.755C135.358 32.8932 135.867 32.9487 136.384 32.9217C137.3 32.8739 138.066 32.6694 138.683 32.3084C139.299 31.924 139.792 31.4521 140.163 30.8926L144.312 33.2119C143.553 34.4492 142.51 35.4313 141.182 36.1583C139.876 36.8605 138.331 37.2583 136.546 37.3516C135.231 37.4203 133.976 37.2863 132.78 36.9495C131.584 36.5894 130.519 36.0462 129.586 35.32C128.675 34.5692 127.931 33.6335 127.354 32.513C126.801 31.3912 126.485 30.0927 126.407 28.6173C126.332 27.1887 126.511 25.8878 126.944 24.7145C127.376 23.5178 127.994 22.4992 128.797 21.6588C129.6 20.795 130.565 20.1105 131.692 19.6054C132.819 19.1003 134.053 18.8128 135.391 18.7428C136.683 18.6753 137.881 18.8358 138.985 19.2243C140.087 19.5894 141.048 20.1615 141.866 20.9407C142.684 21.7198 143.335 22.6838 143.819 23.8327C144.327 24.9803 144.62 26.3035 144.699 27.8023Z" />
      <path d="M158.91 22.7975C158.648 22.7408 158.387 22.7074 158.127 22.6975C157.891 22.6864 157.656 22.6869 157.421 22.6992C156.646 22.7397 155.995 22.9146 155.47 23.2239C154.968 23.5319 154.563 23.8935 154.255 24.3089C153.971 24.7229 153.77 25.1678 153.654 25.6436C153.538 26.1193 153.489 26.5328 153.507 26.8841L153.98 35.8769L148.202 36.1789L147.283 18.685L152.849 18.3941L152.982 20.9234L153.053 20.9197C153.452 20.0064 154.048 19.2473 154.841 18.6423C155.633 18.0373 156.569 17.7066 157.65 17.6501C157.885 17.6378 158.108 17.6379 158.321 17.6503C158.557 17.6614 158.747 17.6867 158.89 17.7262L158.91 22.7975Z" />
    </svg>
  );
}
