export default function Arrow({
  colorClass = "text-gray-900",
  widthClass = "w-[30px]",
  heightClass = "h-[28px]",
  rotationClass = "",
  className = "",
  onClick,
}: {
  colorClass?: string;
  widthClass?: string;
  heightClass?: string;
  rotationClass?: string;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <svg
      viewBox="0 0 24 24"
      className={`transform select-none fill-current transition-transform ${rotationClass} ${widthClass} ${heightClass} ${colorClass} ${className}`}
      onClick={onClick}
    >
      <g>
        <path d="M8.914 12l6.793-6.793c.39-.39.39-1.023 0-1.414s-1.023-.39-1.414 0l-7.5 7.5c-.39.39-.39 1.023 0 1.414l7.5 7.5c.195.195.45.293.707.293s.512-.098.707-.293c.39-.39.39-1.023 0-1.414L8.914 12z" />
      </g>
    </svg>
  );
}
