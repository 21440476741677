export default function Apple({
  widthClass = "w-6",
  heightClass = "h-6",
  colorClass = "text-white",
}: {
  widthClass?: string;
  heightClass?: string;
  colorClass?: string;
}) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={`flex items-center justify-center ${widthClass} ${heightClass} ${colorClass}`}
    >
      <path
        d="M41.2023 16.818C39.7266 17.7211 38.5042 18.9839 37.6495 20.4881C36.7948 21.9924 36.3358 23.6889 36.3155 25.4189C36.3213 27.3659 36.8981 29.2684 37.9746 30.8908C39.051 32.5132 40.5797 33.7842 42.3713 34.5464C41.665 36.8254 40.6195 38.9852 39.2702 40.953C37.3395 43.7322 35.3208 46.5114 32.2492 46.5114C29.1775 46.5114 28.3876 44.7268 24.8476 44.7268C21.3952 44.7268 20.1669 46.57 17.3593 46.57C14.5517 46.57 12.5913 43.9955 10.3388 40.8361C7.36297 36.4101 5.72752 31.2194 5.62866 25.8869C5.62866 17.11 11.3334 12.459 16.9501 12.459C19.9343 12.459 22.4205 14.4191 24.2926 14.4191C26.0771 14.4191 28.8563 12.342 32.2499 12.342C33.9947 12.2969 35.7239 12.6815 37.2851 13.462C38.8462 14.2426 40.1915 15.3951 41.2023 16.818ZM24.5272 11.8446C24.321 11.844 24.1154 11.8244 23.9128 11.7862C23.8556 11.5169 23.8262 11.2423 23.825 10.9669C23.9227 8.70277 24.8014 6.5423 26.3117 4.85274C28.0069 2.90555 30.383 1.68088 32.9525 1.42999C33.0136 1.72835 33.043 2.03232 33.0403 2.33686C32.9845 4.64628 32.1377 6.86656 30.6414 8.62656C29.8936 9.54936 28.9659 10.3105 27.9149 10.8637C26.8638 11.4169 25.7112 11.7506 24.5272 11.8446Z"
        fill="currentColor"
      />
    </svg>
  );
}
