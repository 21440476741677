export default function Venue({
  colorClass = "text-gray-900",
  widthClass = "w-[28px]",
  heightClass = "h-[25px]",
}: {
  colorClass?: string;
  widthClass?: string;
  heightClass?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 222.78 289.75"
      fill="currentColor"
      className={`${colorClass} ${widthClass} ${heightClass}`}
    >
      <path d="m119.84,0c3.06.44,6.13.79,9.17,1.33,27.28,4.82,50.1,17.6,67.91,38.83,13.55,16.15,21.57,34.86,24.85,55.73,2.98,18.94-.89,36.62-8.46,53.85-11.71,26.66-27.85,50.59-45.32,73.69-17.24,22.8-35.83,44.47-55.54,65.17-.31.33-.66.62-1.23,1.14-6.07-6.62-12.14-13.05-18.01-19.65-19.29-21.72-37.65-44.18-54.06-68.18-12.19-17.82-23.14-36.33-31.55-56.3C-1.27,124.52-2.28,103.16,4.07,81.29c5.75-19.81,16.02-36.84,31.03-50.98C51.68,14.7,71.34,5.27,93.7,1.33,96.74.8,99.81.44,102.86,0c5.66,0,11.32,0,16.97,0Zm37.48,111.47c.01-25.48-20.48-45.98-45.96-45.99-25.48,0-45.98,20.49-45.98,45.97,0,25.49,20.48,45.98,45.96,45.98,25.48,0,45.96-20.47,45.98-45.97Z" />{" "}
    </svg>
  );
}
