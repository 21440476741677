export default function VolumeMax({
  colorClass = "text-white",
  widthClass,
  heightClass,
}: {
  colorClass?: string;
  widthClass: string;
  heightClass: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300.24 211.55"
      className={`
        ${widthClass} ${heightClass}
        fill-current select-none
        ${colorClass}
      `}
    >
      <path d="m0,75.48c2.03-4.84,4.87-8.89,10.39-10.09,1.51-.33,3.09-.43,4.63-.43,11.72-.03,23.45-.07,35.17.02,2.19.02,3.84-.6,5.53-2,23.65-19.68,47.34-39.31,71.05-58.92,6.02-4.98,12.2-5.37,17.95-1.23,3.08,2.22,4.84,5.3,5.34,9.05.18,1.35.17,2.73.17,4.1,0,59.89-.02,119.78.05,179.67,0,6.68-2.11,11.89-8.42,14.7-5.39,2.39-10.29,1.07-14.7-2.58-12.18-10.09-24.37-20.19-36.55-30.28-11.81-9.78-23.58-19.61-35.46-29.3-1.16-.94-2.94-1.56-4.44-1.58-11.14-.13-22.28-.36-33.41.03-8.44.3-14.37-2.55-17.28-10.73v-60.41Z" />
      <path d="m234.77,105.63c-.56,23.93-7.67,45.53-21.72,64.84-4.61,6.33-12.71,7.94-18.91,3.85-7.8-5.14-10.35-13.68-5.65-20.68,5.86-8.74,10.92-17.79,13.37-28.1,5.69-23.93,1.72-46.04-12.48-66.25-1.89-2.69-4.03-5.42-5.05-8.47-2.22-6.65,1.73-14.08,8.74-17.61,6.04-3.04,12.73-1.44,17.24,4.23,11.07,13.91,18.66,29.49,21.84,47.02,1.27,6.99,1.77,14.11,2.62,21.18Z" />
    </svg>
  );
}
