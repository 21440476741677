export default function Location({
  colorClass = "text-gray-900",
  widthClass = "w-[30px]",
  heightClass = "h-[28px]",
}: {
  colorClass: string;
  widthClass: string;
  heightClass: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 28"
      fill="currentColor"
      className={`${colorClass} ${widthClass} ${heightClass}`}
    >
      <rect x="4" y="12" width="22" height="4" />
      <rect x="4" y="4" width="22" height="4" />
      <rect x="4" y="20" width="22" height="4" />
    </svg>
  );
}
