export default function Loading({
  colorClass = "text-gray-900",
  widthClass = "w-6",
  heightClass = "h-6",
}: {
  colorClass?: string;
  widthClass?: string;
  heightClass?: string;
}) {
  return (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={`${widthClass} ${heightClass} ${colorClass} fill-current select-none animate-spin`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00001 -2.47001e-05L8.00001 0.999975C7.66351 0.999975 7.32939 1.02421 7.00001 1.07175C5.58976 1.27529 4.26642 1.90603 3.21487 2.89093C1.91771 4.10585 1.13124 5.7687 1.015 7.54215C0.913291 9.09393 1.33139 10.6287 2.19283 11.9085C2.31589 12.0914 2.448 12.269 2.58894 12.4407C3.71642 13.8146 5.32427 14.7081 7.08633 14.9401C8.84839 15.1721 10.6327 14.7251 12.0774 13.6899C13.522 12.6547 14.5188 11.1087 14.8655 9.36561C15.1689 7.84038 14.9547 6.26415 14.2677 4.88284C14.1695 4.68551 14.0617 4.49216 13.9444 4.3035C13.1836 3.08 12.0686 2.12825 10.7591 1.56664C10.4532 1.43547 10.1368 1.32559 9.81174 1.23849L10.0706 0.272568L10.0735 0.27336C10.3971 0.360183 10.7131 0.466766 11.0203 0.591972C12.5743 1.22554 13.8985 2.33591 14.7936 3.77543C15.8662 5.50029 16.2426 7.56857 15.8463 9.5607C15.45 11.5528 14.3109 13.3196 12.6598 14.5027C11.0088 15.6859 8.96959 16.1967 6.9558 15.9315C4.94202 15.6664 3.10448 14.6452 1.81593 13.0751C0.527376 11.505 -0.115703 9.50356 0.0171408 7.47675C0.149985 5.44994 1.0488 3.54955 2.53127 2.16106C3.7685 1.00227 5.33492 0.272452 7.00001 0.0626965C7.33007 0.0211183 7.664 -2.47041e-05 8.00001 -2.47001e-05Z"
        fill={colorClass}
      />
    </svg>
  );
}
