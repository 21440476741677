export default function Heart({
  colorClass = "text-yellow-100",
  widthClass = "w-6",
  heightClass = "h-6",
  filled = false,
}: {
  colorClass?: string;
  widthClass?: string;
  heightClass?: string;
  filled?: boolean;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={`
        ${widthClass} ${heightClass}
        ${colorClass}
        fill-current
        select-none
      `}
    >
      {filled ? (
        <path d="M2.23125 14.0813L10.7016 21.9891C11.0531 22.3172 11.5172 22.5 12 22.5C12.4828 22.5 12.9469 22.3172 13.2984 21.9891L21.7687 14.0813C23.1937 12.7547 24 10.8938 24 8.94844V8.67657C24 5.40001 21.6328 2.60626 18.4031 2.06719C16.2656 1.71094 14.0906 2.40938 12.5625 3.93751L12 4.50001L11.4375 3.93751C9.90938 2.40938 7.73438 1.71094 5.59688 2.06719C2.36719 2.60626 0 5.40001 0 8.67657V8.94844C0 10.8938 0.80625 12.7547 2.23125 14.0813Z" />
      ) : (
        <path d="M10.5844 21.9469L10.4672 21.8391L2.25469 14.2125C0.815625 12.8766 0 11.0016 0 9.0375V8.88281C0 5.58281 2.34375 2.75156 5.5875 2.13281C7.43437 1.77656 9.32344 2.20312 10.8281 3.2625C11.25 3.5625 11.6438 3.90937 12 4.30781C12.1969 4.08281 12.4078 3.87656 12.6328 3.68437C12.8063 3.53437 12.9844 3.39375 13.1719 3.2625C14.6766 2.20312 16.5656 1.77656 18.4125 2.12812C21.6562 2.74687 24 5.58281 24 8.88281V9.0375C24 11.0016 23.1844 12.8766 21.7453 14.2125L13.5328 21.8391L13.4156 21.9469C13.0313 22.3031 12.525 22.5047 12 22.5047C11.475 22.5047 10.9688 22.3078 10.5844 21.9469ZM11.2078 6.79687C11.1891 6.78281 11.175 6.76406 11.1609 6.74531L10.3266 5.80781L10.3219 5.80312C9.23906 4.58906 7.60312 4.03593 6.00937 4.34062C3.825 4.75781 2.25 6.66093 2.25 8.88281V9.0375C2.25 10.3734 2.80781 11.6531 3.7875 12.5625L12 20.1891L20.2125 12.5625C21.1922 11.6531 21.75 10.3734 21.75 9.0375V8.88281C21.75 6.66562 20.175 4.75781 17.9953 4.34062C16.4016 4.03593 14.7609 4.59375 13.6828 5.80312C13.6828 5.80312 13.6828 5.80312 13.6781 5.80781C13.6734 5.8125 13.6781 5.80781 13.6734 5.8125L12.8391 6.75C12.825 6.76875 12.8062 6.78281 12.7922 6.80156C12.5812 7.0125 12.2953 7.12969 12 7.12969C11.7047 7.12969 11.4188 7.0125 11.2078 6.80156V6.79687Z" />
      )}
    </svg>
  );
}
