export default function Stop({
  colorClass = "text-gray-900",
  widthClass = "w-[8px]",
  heightClass = "h-[8px]",
}: {
  colorClass: string;
  widthClass: string;
  heightClass: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 8"
      fill="currentColor"
      className={`
        ${widthClass} ${heightClass}
        stroke-current select-none
        ${colorClass}
      `}
    >
      <rect width="8" height="8" />
    </svg>
  );
}
