export default function Plus({
  colorClass = "text-gray-900",
  widthClass = "w-[14px]",
  heightClass = "h-[16px]",
  className = "",
}: {
  colorClass?: string;
  widthClass?: string;
  heightClass?: string;
  className?: string;
}) {
  return (
    <svg
      viewBox="0 0 448 512"
      className={`fill-current select-none transform transition-transform ${widthClass} ${heightClass} ${colorClass} ${className}`}
    >
      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
    </svg>
  );
}
