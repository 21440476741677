export default function Pause({
  colorClass,
  widthClass,
  heightClass,
}: {
  colorClass: string;
  widthClass: string;
  heightClass: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 6 8"
      fill="none"
      className={`
        ${widthClass} ${heightClass}
        stroke-current select-none
        ${colorClass}
      `}
    >
      <path d="M1 0V8M5 0V8" strokeWidth="2" />
    </svg>
  );
}
